import React from "react";
import SideBarSocialIcon from "../../components/SideBarSocialIcon/SideBarSocialIcon";
import Footer from "../FrontPage/components/Footer";
import Header from "../FrontPage/components/Header";
import Particle from "../FrontPage/components/Particle";

const TermsConditions = ({ title, para }) => {
  return (
    <div className="termAndConditions_body">
      <Header />
      <SideBarSocialIcon />
      <div className="container">
        <div className="termAndConditions_sub">
          <h1 className="termAndConditions">Term and Conditions</h1>
          <h2 className="termAndConditionsWelcome">Welcome to Safe & Secure Trade!</h2>
          <div className="termAndConditionBasicDetails">
            <p>
              These terms and conditions outline the rules and regulations for
              the use of Safe & Secure Trade's Website, located at https://Safe & Secure Trade.org.
            </p>
            <p>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use Safe & Secure Trade if you do not agree to
              take all of the terms and conditions stated on this page.
            </p>
            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company's terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client's needs in respect of
              provision of the Company's stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same. Cookies
            </p>
            <p>
              We employ the use of cookies. By accessing Safe & Secure Trade, you agreed to use
              cookies in agreement with the Safe & Secure Trade's Privacy Policy.
            </p>
            <p>
              Most interactive websites use cookies to let us retrieve the
              user's details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies. License
            </p>
            <p>
              Unless otherwise stated, Safe & Secure Trade and/or its licensors own the
              intellectual property rights for all material on Safe & Secure Trade. All
              intellectual property rights are reserved. You may access this
              from Safe & Secure Trade for your own personal use subjected to restrictions set
              in these terms and conditions.
            </p>
          </div>
          <div className="termAndConditionBasicDetails ">
            {/* <h2></h2> */}
            <ul className="showAndList">
              <h2>You must not:</h2>
              <li>Republish material from Safe & Secure Trade</li>
              <li>Sell, rent or sub-license material from Safe & Secure Trade</li>
              <li>Reproduce, duplicate or copy material from Safe & Secure Trade</li>
              <li>Redistribute content from Safe & Secure Trade</li>
            </ul>
          </div>
          <div className="termAndConditionBasicDetails">
            <p>
              This Agreement shall begin on the date hereof. Our Terms and
              Conditions were created with the help of the Free Terms and
              Conditions Generator.
            </p>
            <p>
              Parts of this website offer an opportunity for users to post and
              exchange opinions and information in certain areas of the website.
              Safe & Secure Trade does not filter, edit, publish or review Comments prior to
              their presence on the website. Comments do not reflect the views
              and opinions of Safe & Secure Trade,its agents and/or affiliates. Comments
              reflect the views and opinions of the person who post their views
              and opinions. To the extent permitted by applicable laws, Safe & Secure Trade
              shall not be liable for the Comments or for any liability, damages
              or expenses caused and/or suffered as a result of any use of
              and/or posting of and/or appearance of the Comments on this
              website.
            </p>
            <p>
              Safe & Secure Trade reserves the right to monitor all Comments and to remove any
              Comments which can be considered inappropriate, offensive or
              causes breach of these Terms and Conditions.
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <p>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </p>
            <p>
              {" "}
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </p>
            <p>
              {" "}
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </p>
            <p>
              {" "}
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <p>
              You hereby grant Safe & Secure Trade a non-exclusive license to use, reproduce,
              edit and authorize others to use, reproduce and edit any of your
              Comments in any and all forms, formats or media. Hyperlinking to
              our Content
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <h2>
              The following organizations may link to our Website without prior
              written approval:
            </h2>
            <p>
              Government agencies; Search engines; News organizations; Online
              directory distributors may link to our Website in the same manner
              as they hyperlink to the Websites of other listed businesses; and
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </p>
            <ul className="showAndList">
              <h2>
                These organizations may link to our home page, to publications
                or to other Website information so long as the link:{" "}
              </h2>
              <li>(a) is not in any way deceptive</li>
              <li>
                (b) does not falsely imply sponsorship, endorsement or approval
                of the linking party and its products and/or services; and
              </li>
              <li>(c) fits within the context of the linking party's site.</li>
            </ul>
          </div>
          <div className="termAndConditionBasicDetails">
            <ul className="showAndList">
              <h2>
                We may consider and approve other link requests from the
                following types of organizations:
              </h2>
              <li>
                {" "}
                commonly-known consumer and/or business information sources;
                dot.com community sites;
              </li>
              <li>
                associations or other groups representing charities; online
                directory distributors;
              </li>
              <li> internet portals;</li>
              <li> accounting, law and consulting firms; and </li>
              <li>educational institutions and trade associations. </li>
            </ul>
          </div>
          <div className="termAndConditionBasicDetails">
            <ul className="showAndList">
              <h2>
                We will approve link requests from these organizations if we
                decide that:
              </h2>
              <li>
                {" "}
                (a) the link would not make us look unfavorably to ourselves or
                to our accredited businesses;
              </li>
              <li>
                (b) the organization does not have any negative records with us;
              </li>
              <li>
                {" "}
                (c) the benefit to us from the visibility of the hyperlink
                compensates the absence of Safe & Secure Trade; and
              </li>
              <li>
                {" "}
                (d) the link is in the context of general resource information.
              </li>
            </ul>
            <ul className="showAndList">
              <h2>
                These organizations may link to our home page so long as the
                link:
              </h2>
              <li> (a) is not in any way deceptive;</li>
              <li>
                (b) does not falsely imply sponsorship, endorsement or approval
                of the linking party and its products or services; and
              </li>
              <li> (c) fits within the context of the linking party's site.</li>
            </ul>
            <p></p>
            <p>
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our website, you must inform us
              by sending an e-mail to Safe & Secure Trade. Please include your name, your
              organization name, contact information as well as the URL of your
              site, a list of any URLs from which you intend to link to our
              Website, and a list of the URLs on our site to which you would
              like to link. Wait 2-3 weeks for a response
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <h2>
              Approved organizations may hyperlink to our Website as follows:
            </h2>
            <ul className="showAndList">
              <li>By use of our corporate name; or</li>
              <li>
                {" "}
                By use of the uniform resource locator being linked to; or
              </li>
              <li>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party's site.
              </li>
            </ul>
          </div>
          <div className="termAndConditionBasicDetails">
            <p>
              No use of Safe & Secure Trade's logo or other artwork will be allowed for linking
              absent a trademark license agreement. iFrames
            </p>
            <p>
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website. Content Liability
            </p>
            <p>
              We shall not be hold responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights. Your
              Privacy
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <p>Please read Privacy Policy Reservation of Rights</p>
            <p>
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amen these terms and conditions and it's linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </p>
            <p>Removal of links from our website</p>
            <p>
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly.
            </p>
            <p>
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </p>
          </div>
          <div className="termAndConditionBasicDetails">
            <h1>Disclaimer</h1>
            <h2>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website. Nothing in this disclaimer will:
            </h2>
            <ul className="showAndList">
              <li>
                limit or exclude our or your liability for death or personal
                injury;
              </li>
              <li>
                {" "}
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </li>
              <li>
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </li>
              <li>
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </li>
            </ul>
          </div>
          <div className="termAndConditionBasicDetails">
            <ul className="showAndList">
              <h2>
                {" "}
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer:
              </h2>
              <li> (a) are subject to the preceding paragraph; and </li>
              <li>
                (b) govern all liabilities arising under the disclaimer,
                including liabilities arising in contract, in tort and for
                breach of statutory duty.
              </li>
            </ul>
            <p>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
