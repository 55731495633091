import React, { useEffect, useState } from "react";
import GalleryImageCard from "../../../components/GalleryImageCard/GalleryImageCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoGrid from "../../../components/VideoGrid/VideoGrid";
import { useGetAllImageQuery } from "../../../Services/userApi";

const Gallery = () => {
  const { data, error } = useGetAllImageQuery();
  console.log("image Data", data);
  const itemsPerPage = 10;
  const [startIndex, setStartIndex] = useState(0);
  const [key, setKey] = useState(0); // Add key state

  const handleNextClick = () => {
    setStartIndex((prevIndex) => prevIndex + itemsPerPage);
  };

  const handlePreviousClick = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - itemsPerPage));
  };

  const visibleData =
    data?.data?.slice(startIndex, startIndex + itemsPerPage) || [];
  // console.log(visibleData);

  useEffect(() => {
    // Update the key whenever startIndex changes
    setKey((prevKey) => prevKey + 1);
  }, [startIndex]);

  return (
    <div className='gallery_body'>
      <Header hidePackageRoute />
      {/* for Images */}
      <div className='gallery_wraper'>
        <h3>Image Gallery</h3>

        <div className='gallery_container'>
          {visibleData?.map((d, i) => (
            <GalleryImageCard key={i} data={d} index={i} />
          ))}
        </div>
        <div className='imgGallery_button_container'>
          <button onClick={handlePreviousClick}>Prev</button>
          <button onClick={handleNextClick} disabled={visibleData?.length <10}>Next</button>
        </div>
      </div>
      {/* For Videos */}
      <div className='gallery_wraper'>
        <h3>Video Gallery</h3>
        <div className='gallery_container'>
          <VideoGrid />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
