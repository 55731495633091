import React from "react";
import herobg from "../../../../assets/homeImg.png";

const HeroHome = () => {
  return (
    <div className="sst_home-banner">
      <div className="container sst_hero-container">
        <div className="sst_herro-left">
          <div className="sst_hero-subtitle">
            <h4> Discover a new ways to enjoy your World!</h4>
          </div>
          <div className="sst_hero-title">
            <h1> Forex Trading.</h1>
          </div>
          <div className="sst_hero-text">
            <p>
              A community of like-minded traders and investors working together
              to earn money and build financial freedom.
            </p>
          </div>
          {/* <div className="sst_hero-buttons">
            <button>Contact Us</button>
          </div> */}
        </div>
        <div className="sst_herro-right">
          <img src={herobg} />
        </div>
      </div>
    </div>
  );
};

export default HeroHome;
