import React, { useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

const GalleryImageCard = ({
  data,
  index,
  deleteGalleryImageByAdmin,
  Isadmin,
}) => {
  // state for delete confirm modal
  const [condfirmDelete, setConfirmDelete] = useState(false);
  const handleDelete = async (publicUrl) => {
    await deleteGalleryImageByAdmin({ avatarPublicUrl: publicUrl });
  };
  return (
    <div>
      <div class={"small-card"}>
        <PhotoProvider
          speed={() => 800}
          easing={(type) =>
            type === 2
              ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
              : "cubic-bezier(0.34, 1.56, 0.64, 1)"
          }
        >
          <div className="img_container">
            <PhotoView src={data?.avatar}>
              <img src={data?.avatar} alt="" />
            </PhotoView>
          </div>
        </PhotoProvider>
      </div>
      <div className="button_container">
        {Isadmin && (
          <button onClick={() => setConfirmDelete(true)}>Delete Image</button>
        )}
      </div>
      {condfirmDelete && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Are you sure you want to delete?</h2>
            <div className="button-container">
              <button
                onClick={() =>
                  handleDelete(data?.avatarPublicUrl) && setConfirmDelete(false)
                }
              >
                Delete
              </button>
              <button onClick={() => setConfirmDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryImageCard;
