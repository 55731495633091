import React from "react";
import img1 from "../../../../assets/core_service/1.png";
import img2 from "../../../../assets/core_service/2.png";
import img3 from "../../../../assets/core_service/3.png";
const OurCoreServices = () => {
  return (
    <div className="core-service-container">
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="text-content">
        <h2>Our Core Services</h2>
      </div>
      <div className="grid-container container">
        <div className="service_single_content">
          <div class="service_icon">
            <img draggable="false" src={img1} alt="" />
          </div>
          <h2>Safe & Secure </h2>
          <p>
            Our platform is designed to protect your account security and funds
            safe.
          </p>
        </div>
        <div className="service_single_content">
          <div class="service_icon">
            <img draggable="false" src={img2} alt="" />
          </div>
          <h2>24/7 Support </h2>
          <p>Regularly evaluates our talent to ensure quality</p>
        </div>
        <div className="service_single_content">
          <div class="service_icon">
            <img draggable="false" src={img3} alt="" />
          </div>
          <h2> Personal Account </h2>
          <p>
            Our easy-to-use personal Account system will keep your funds safe.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurCoreServices;
