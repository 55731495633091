import React from "react";
const WeAreTrusted = () => {
  return (
    <div className='trusted_container'>
      <div className='dots'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default WeAreTrusted;
