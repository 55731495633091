import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("safe_secure_token"));
      return headers;
    },
  }),
  tagTypes: ["setting"], // automatic-data fetching
  endpoints: (builder) => ({
    // get popup image
    getPopupImage: builder.query({
      // SAST API
      query: () => "/api/get_popup_img",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getYoutube: builder.query({
      query: () => "/api/v1/public/get_video_link",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getPdfLink: builder.query({
      query: () => "/api/v1/public/get_pdf_link",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getWebsiteAnalytics: builder.query({
      query: () => "/api/v1/public/website_analytics",
    }),
  }),
});

export const {
  useGetPopupImageQuery, //
  useGetYoutubeQuery, //
  useAddPdfLinkMutation,
  useGetPdfLinkQuery, //
  useGetWebsiteAnalyticsQuery, //
} = settingApi;
