import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const supportApi = createApi({
  reducerPath: "supportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("safe_secure_token"));
      return headers;
    },
  }),
  tagTypes: ["adminSupport", "userSupport"], // automatic-data fetching
  endpoints: (builder) => ({
    // user
    getUpdates: builder.query({
      query: () => `/api/v1/secure/get_updates`,
      providesTags: ["userSupport"], // automatic-data fetching
    }),
    contactUsHistory: builder.query({
      query: () => `/secure/api/get_contactus_history`,
      providesTags: ["userSupport"], // automatic-data fetching
    }),
    ticketHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_support_history?page=${query.page}&limit=${query.limit}`,
      providesTags: ["userSupport"], // automatic-data fetching
    }),
    addContactMessage: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/contactus_message",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userSupport"], // automatic-data fetching
    }),
    addSupportTicket: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/create_support",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userSupport"], // automatic-data fetching
    }),
    //for getting all news
    getAllNews: builder.query({
      query: (query) =>
        `/api/v1/secure/get_all_news?page=${query.page}&limit=${query.limit}`,
      providesTags: ["userSupport"], // automatic-data fetching
    }),
  }),
});

export const {
  useGetUpdatesQuery, //
  useContactUsHistoryQuery, //
  useTicketHistoryQuery, //
  useAddContactMessageMutation, //
  useAddSupportTicketMutation, //
  useGetAllNewsQuery, //
} = supportApi;
