import React from "react";
import FAQ from "./faq";
import HeroHome from "../HeroHome";
import WeAreTrusted from "./WeAreTrusted";
import OurCoreServices from "./OurCoreServices";
import Newslettersection from "../Newsletter/Newslettersection";
const Home = () => {
  return (
    <>
      <HeroHome/>
      <WeAreTrusted />
      <OurCoreServices/>
      <FAQ />
      <Newslettersection/>
    </>
  );
};

export default Home;
