import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const withdrawApi = createApi({
  reducerPath: "withdrawApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("safe_secure_token"));
      return headers;
    },
  }),
  tagTypes: ["adminWithdraw", "userWithdraw", "autoTradeWithdraw"], // automatic-data fetching
  endpoints: (builder) => ({
    getAdminAllBalance: builder.query({
      query: () => "/payment/api/get_admin_balance",
      providesTags: ["adminWithdraw"], // automatic-data fetching
    }),
    sendTokenAdmin: builder.mutation({
      query: (body) => ({
        url: "/payment/api/send_token",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminWithdraw"],
    }),
  }),
});

export const {
  useSendTokenAdminMutation,
  useGetAdminAllBalanceQuery,
} = withdrawApi;
