import React, { useEffect, useState } from "react";
import VideoItem from "./VideoItem/VideoItem";
import { useGetAllVedioQuery } from "../../Services/userApi";

// const videos = [
//   // Array of video URLs
//   "https://www.youtube.com/watch?v=tb-TAVqYl4s",
//   "https://www.youtube.com/watch?v=3nQNiWdeH2Q",
//   "https://www.youtube.com/watch?v=eUb4XPIMdkg",
//   // Add more video URLs as needed
// ];

const VideoGrid = ({ Isadmin, deleteGalleryVideoByAdmin }) => {
  const { data, error } = useGetAllVedioQuery();
  // console.log("my vedio", data);
  const itemsPerPage = 4;
  const [startIndex, setStartIndex] = useState(0);
  const [key, setKey] = useState(0); // Add key state

  const handleNextClick = () => {
    setStartIndex((prevIndex) => prevIndex + itemsPerPage);
  };

  const handlePreviousClick = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - itemsPerPage));
  };

  const visibleData =
    data?.data?.slice(startIndex, startIndex + itemsPerPage) || [];
  // console.log(visibleData);

  useEffect(() => {
    // Update the key whenever startIndex changes
    setKey((prevKey) => prevKey + 1);
  }, [startIndex]);

  return (
    <div>
      <div className='video-grid'>
        {visibleData?.map((video, index) => (
          <VideoItem
            key={index}
            video={video}
            width='240px'
            height='240px'
            Isadmin={Isadmin}
            deleteGalleryVideoByAdmin={deleteGalleryVideoByAdmin}
          />
        ))}
      </div>
      <div className='imgGallery_button_container'>
        <button onClick={handlePreviousClick}>Prev</button>
        <button onClick={handleNextClick} disabled={visibleData?.length < 10}>
          Next
        </button>
      </div>
    </div>
  );
};

export default VideoGrid;
