// VideoItem.js
import React, { useState } from "react";

const VideoItem = ({
  video,
  width,
  height,
  Isadmin,
  deleteGalleryVideoByAdmin,
}) => {
  console.log("my data is ", video);
  const embedUrl = video.vedioLink?.includes("youtube.com/watch")
    ? video.vedioLink?.replace("watch?v=", "embed/")
    : video.vedioLink;

  // state for delete confirm modal
  const [condfirmDelete, setConfirmDelete] = useState(false);
  const handleDelete = async (data) => {
    await deleteGalleryVideoByAdmin({ vedioId: data });
  };

  return (
    <>
      <div className='video-item'>
        <iframe
          title='video'
          // width={width}
          // height={height}
          src={embedUrl}
          frameBorder='0'
          allowFullScreen
        ></iframe>
        {Isadmin && (
          <div className='button_container_video'>
            <button onClick={() => setConfirmDelete(true)}>Delete</button>
          </div>
        )}
        {condfirmDelete && (
          <div className='modal-overlay'>
            <div className='modal'>
              <h2>Are you sure you want to delete?</h2>
              <div className='button-container'>
                <button
                  onClick={() =>
                    handleDelete(video?.vedioId) && setConfirmDelete(false)
                  }
                >
                  Delete
                </button>
                <button onClick={() => setConfirmDelete(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoItem;
